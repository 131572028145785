.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  video {
    height: 100%;
    width: 100%;
  }
  p {
    font-size: 31px;
  }
  .logo {
    margin-top: 17px;
    width: 62%;
    height: 287px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 78px;
  }
  a {
    padding: 10px 50px;
    color: #ffffff;
    background-color: var(--primary-btn-color);
    font-weight: 600;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #ffffff;
    &:hover {
      transition: 0.3s;
      background-color: var(--secondary-btn-color);
      color: white;
    }
  }
  &__container {
    display: flex;
    gap: 10px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    h2 {
      font-size: 60px;
      letter-spacing: 3px;
    }
  }
  &__women {
    color: var(--text-home-color);
    width: 600px;
    height: 428px;
    position: relative;
    &--container {
      position: absolute;
      z-index: 100;
      top: 126px;
      left: 193px;
    }
    &--text {
      padding-bottom: 16px;
      span {
        font-size: 30px;
      }
    }
  }
  &__men {
    color: var(--text-home-color);
    width: 600px;
    height: 428px;
    position: relative;
    &--container {
      position: absolute;
      z-index: 100;
      top: 126px;
      left: 193px;
    }
    &--text {
      padding-bottom: 16px;
      span {
        font-size: 30px;
      }
    }
  }
  @media screen and (max-width: (1400px)) {
  }
  @media screen and (max-width: (1200px)) {
    &__men {
      width: 500px;
    }
    &__women {
      width: 500px;
      &--container {
        top: 172px;
        left: 153px;
      }
    }
  }
  @media screen and (max-width: (991px)) {
    &__men {
      width: 400px;
    }
    &__women {
      width: 400px;
      &--container {
        top: 147px;
        left: 94px;
      }
    }
    &__container {
      justify-content: center;
      gap: 30px;
    }
  }
  @media screen and (max-width: (767px)) {
    margin-top: 0;
    &__container {
      flex-direction: column;
      p,
      span {
        font-size: 27px;
      }
    }
    &__men {
      margin-top: 7px;
      width: 400px;
      height: auto;
    }
    &__women {
      width: 400px;
      height: auto;
      &--container {
        top: 7px;
      }
    }
    .logo {
      width: 96%;
      height: 224px;
      margin-top: 9px;
    }
    &__celebrate {
      background-image: none;
      height: 95px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  @media screen and (max-width: (567px)) {
    overflow: hidden;
    &__container {
      gap: 0;
    }
    &__men {
      width: 400px;

      video {
        height: 55%;
      }
    }
    &__women {
      width: 400px;
      video {
        height: 53%;
      }
      &--container {
        top: 0;
        left: 22px;
      }
    }
    .logo {
      height: 197px;
    }
  }
}
