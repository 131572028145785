* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  // font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-family: 'MontserratAce';
}

body {
  width: 100%;
}


:root{
  --text-home-color: #FFFFFF;
  // --primary-btn-color: #f7b538;
  --secondary-btn-color: #888;
  --primary-product-discount-color: #ae2238;
  --primary-product-btn-color: #FFFFFF;
  --secondary-product-btn-color: #EDEDED;
  --text-product-oldprc-color: #888;
}

.container {
  max-width: 1350px;
  margin: auto;
  @media screen and (max-width: (1400px)) {
    max-width: 1100px;
  }
  @media screen and (max-width: (1200px)) {
    max-width: 900px;
  }
  @media screen and (max-width: (991px)) {
    max-width: 700px;
  }
  @media screen and (max-width: (767px)) {
    max-width: 454px;
  }
  @media screen and (max-width: (567px)) {
    max-width: 467px;
  }
}
